@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
    --background-light-purple: rgba(163, 65, 255, 0.1);
    --background-orange: #F5951C;
    --background-bar-orange: #FFBA62;
    --background-bar-green: #1ECE7E;
    --background-bar-red: #FF4E4E;
    --background-form-save-btn: linear-gradient(
      144deg,
      #9f18ff 22.82%,
      #631cbd 77.64%
    );

    --font-primary: "Inter", sans-serif;
    --font-secondary: "Lexend", sans-serif;
    /* --font-primary: "Clarity City", sans-serif; */
    
    --box-shadow-purple: 0px 0px 0px 1.5px rgba(159, 24, 255, 0.1);
    --box-shadow-form-save-btn: 0px 0px 0px 1px #7622e2;
    --box-shadow-form-purple: 0px 0px 0px 4.2px rgba(159, 24, 255, 0.1);
    --box-shadow-light-purple: 0px 2px 30px 0px rgba(159, 24, 255, 0.08);
    --box-shadow-gray: 0px 0px 5px 1px rgba(0, 0, 0, 0.12);
    --box-shadow-blue: 0px 0px 0px 2px rgba(0, 101, 244, 0.1);
    --box-shadow-light-gray: 0px 0px 0px 1px rgba(0, 56, 109, 0.08);
    --box-shadow-padding: 0px -3px 20px 0px rgba(0, 0, 0, 0.06);
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
  svg {
    @apply inline;
  }
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  --black: #000000;
  --white: #ffffff;
  --primary-blue: #2a74ff;
  --mid-blue: #1e3a8a;
  --dark-blue: #21325e;
  --text-gray: #727272;
  --border-gray: #dadada;
  --successful: #d3f4d4;
  --danger: #ff3f3f;
  --sidebar-link-hover: #b1b8c9;
  --icon-link-hover: rgba(238, 242, 255, 0.2);
  --text-blue: #1e40af;
  --text-indigo: #4338ca;
  --text-blue-grey: #334155;
  --selected-tab: #eef2ff;
  --card-background: #fdfdfd;
  --case-study-text: #64748b;
  --not-found-text: #475569;
  --dropdown-background: #f8fafc;
  --scrollbar-color: #d1d5db;
  --widget-wrapper: #3f3f3f;

}

#root {
  overflow-y: hidden;
}

@media screen and (min-width: 1080px) and (max-width: 1439px) {
  html {
    font-size: 8.75px;
  }
}

@media screen and (min-width: 1520px) {
  html {
    font-size: 10.5px;
  }
}

@media screen and (min-width: 1820px) {
  html {
    font-size: 12.63px;
  }
}
@media screen and (min-width: 1920px) {
  html {
    font-size: 13.3px;
  }
}

body {
  font-family: "Lato", sans-serif;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.text-center {
  text-align: center;
}

.w-70 {
  width: 70vw;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.g-1 {
  gap: 2rem;
}

.g-2 {
  gap: 2rem;
}

@font-face {
  font-family: "Clarity City";
  src: url("/src/assets/fonts/ClarityCity-Regular.woff2") format("woff2");
}

* {
  scrollbar-color: var(--scrollbar-color) #00000000;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-width: 4px;
  background-color: #dadce0;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}


/* Hide arrow on input type number */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
input[type="number"] {
  appearance: textfield;
}